import React from 'react';

import { Label } from '@/ui/label';
import { RadioGroupItem } from '@/ui/radio-group';
import { FormField, FormItem, FormControl, FormLabel } from '@/ui/form';
import { Checkbox } from '@/ui/checkbox';

interface Props {
  item: {
    name: string;
    icon: JSX.Element;
    value: string;
  };
}

const MODCheckbox: React.FC<Props> = ({ item }) => {
  return (
    <FormField
      name="modesOfDelivery"
      render={({ field }) => {
        return (
          <div
            className={`flex flex-col gap-0.5 text-sm ${
              !field.value.includes(item.name) ? 'text-gray-500' : 'text-pi-primary-default'
            } `}
            key={item.name}
          >
            <FormItem className="flex items-center justify-between">
              <FormLabel className="flex items-center gap-4 text-xl">
                {item.name} {item.icon}
              </FormLabel>
              <FormControl>
                <Checkbox
                  checked={field.value?.includes(item.name)}
                  onCheckedChange={(checked) => {
                    return checked
                      ? field.onChange([...field.value, item.name])
                      : field.onChange(field.value?.filter((value: any) => value !== item.name));
                  }}
                />
              </FormControl>
            </FormItem>
            <div className="flex items-center space-x-2">
              <RadioGroupItem id={item.name} value={item.name} />
              <Label htmlFor={item.name}>Main mode of delivery</Label>
            </div>
          </div>
        );
      }}
    />
  );
};

export default MODCheckbox;
