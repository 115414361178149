import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, Truck, MessageSquare, LayoutDashboard, ScanLine, User } from 'lucide-react';
import { Footer } from '@/ui/footer';
import LayoutButton from './components/LayoutButton';
import useLocalStorage from '@/hooks/useLocalStorage';

const SENDER_MENU_ITEMS = [
  {
    title: 'Home',
    icon: Home,
    path: '/sender',
  },
  {
    title: 'Delivery',
    icon: Truck,
    path: '/sender/delivery',
  },
  {
    title: 'Chat',
    icon: MessageSquare,
    path: '/chat',
  },
  {
    title: 'Profile',
    icon: User,
    path: '/profile',
  },
];

const COURIER_MENU_ITEMS = [
  {
    title: 'Home',
    icon: Home,
    path: '/courier',
  },
  {
    title: 'Chat',
    icon: MessageSquare,
    path: '/chat',
  },
  {
    title: 'DashBoard',
    icon: LayoutDashboard,
    path: '/courier/dashboard',
  },
  {
    title: 'Vcode',
    icon: ScanLine,
    path: '/courier/verification-code',
  },
  {
    title: 'Profile',
    icon: User,
    path: '/profile',
  },
];

export default function AppLayout({ children }: { children: React.ReactNode[] | React.ReactNode }) {
  const { value } = useLocalStorage();
  const location = useLocation();

  const MENU_ITEMS = value === 'sender' ? SENDER_MENU_ITEMS : COURIER_MENU_ITEMS;

  return (
    <div className="flex h-[100dvh] flex-col bg-gray-100">
      <div className=" flex flex-1 flex-col overflow-y-auto bg-gray-100 ">{children}</div>
      <div className="h-[8vh] flex-shrink-0">
        <Footer className="fixed bottom-0 z-50 flex flex-col items-center gap-4 border border-t-[1]  bg-base-white pb-2 pt-4">
          <div className="mb-4 flex w-[100%] justify-around">
            {MENU_ITEMS.map((item) => {
              const { title, icon: Icon, path } = item;
              const isActive = location.pathname === path;

              return (
                <Link key={title} to={path}>
                  <LayoutButton path={path}>
                    <Icon fill={isActive ? '#1E04BB' : 'none'} />
                    <span className="">{title}</span>
                  </LayoutButton>
                </Link>
              );
            })}
          </div>
        </Footer>
      </div>
    </div>
  );
}
