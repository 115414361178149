import { useNavigate, useParams } from 'react-router-dom';
import VCodeSection from './components/request-item/v-code-section';
import { useGetDeliveryByIdQuery } from '@/services/delivery.services';
import { Loader } from '@/components';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import RequestItem from './components/request-item/request-item';

const VerificationDeliveryDetails = () => {
  const navigate = useNavigate();
  const { deliveryId } = useParams();

  const { data, isLoading } = useGetDeliveryByIdQuery(
    { id: deliveryId as string },
    {
      skip: !deliveryId,
    }
  );

  if (!deliveryId) {
    navigate('/courier');
    return;
  }

  if (isLoading || !data) {
    return <Loader />;
  }
  const statusInfo =
    data.deliveryStatus === DeliveryStatus.ACCEPTED ||
    data.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER
      ? 'Awaiting Pickup'
      : data.deliveryStatus === DeliveryStatus.PICKED_UP ||
        data.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER
      ? 'Awaiting Drop Off'
      : 'Delivered';

  return (
    <RequestItem delivery={data} navigationText="Verification details" statusInfo={statusInfo}>
      <VCodeSection delivery={data} />
    </RequestItem>
  );
};

export default VerificationDeliveryDetails;
