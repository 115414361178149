import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/ui/form';
import { Input } from '@/ui/input';
import { Props } from './state-machine/courier-setup-machine';
import { Button } from '@/ui/button';
import InputWithPiIcon from '@/components/Input-with-pi-icon';
import { UseFormReturn } from 'react-hook-form';
import FormHeader from '@/components/delivery-form/components/form-header';
import { CourierSetupForm } from './setup-form';

function CourierPriceForm({
  send,
  state,
  form,
}: Props & {
  form: UseFormReturn<CourierSetupForm, any, CourierSetupForm>;
}) {
  const {
    formState: { isValid },
    register,
  } = form;

  return (
    <div className="flex h-screen flex-col justify-between px-5 pb-20">
      <div>
        <FormHeader
          description="The amount you are willing to accept for deliveries"
          onGoBack={() => send({ type: 'PREVIOUS' })}
          step={state.context.step}
          title="Let us Know your price for delivery"
        />
        <FormField
          name="preferredPrice"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Preferred Price for deliveries</FormLabel>
              <FormControl>
                <InputWithPiIcon>
                  <Input
                    step="any"
                    className="mt-0 rounded-none border-0 border-none bg-transparent focus:border-0 focus:border-none"
                    min={0}
                    type="number"
                    {...field}
                    {...register('preferredPrice', {
                      required: 'Price for delivery is required',
                    })}
                    placeholder="e.g 0.0002"
                  />
                </InputWithPiIcon>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <Button disabled={!isValid} onClick={() => send({ type: 'NEXT' })} size="large">
        Next
      </Button>
    </div>
  );
}

export default CourierPriceForm;
