import { CheckCircle2 } from "lucide-react";
import { MapPin } from "lucide-react";
import { slippers, userIcon } from "@/assets/images";
import React from "react";

const SuccessfulDeliveryItem = () => {
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-base-white p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img
            alt="Sender Icon"
            className="aspect-square h-[20px] rounded-full"
            src={userIcon}
          />
          <span className="text-xs text-gray-500">@piusername</span>
        </div>
        <span className="flex items-center gap-2 text-base font-semibold text-pi-primary-default">
          0.000034π{" "}
          <span className="text-green-600">
            <CheckCircle2 size={14} />
          </span>
        </span>
      </div>
      <div className="flex gap-4 text-gray-600">
        <MapPin size={18} />
        <p className="flex-1 text-sm text-gray-600">
          2464 Royal Ln. Mesa, New Jersey 45463
        </p>
        <div className="flex flex-col items-center gap-2">
          <img
            alt="Item"
            className="aspect-square
          h-[40px] rounded-lg border
          border-gray-300 object-cover
          "
            src={slippers}
          />
          <span className="text-[10px] font-semibold text-pi-primary-default">
            view details
          </span>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulDeliveryItem;
