import AppLayout from '../app-layout/app-layout';
import { ListFilter, ChevronRight, StickyNote } from 'lucide-react';
import { Button } from '@/ui/button';
import { Card } from '@/ui/card';
import { useNavigate } from 'react-router-dom';
import { useGetDeliveriesByCourierQuery } from '@/services/delivery.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { Loader } from '@/components';
import { format } from 'date-fns';

const VerificationCode = () => {
  const { data, isLoading } = useGetDeliveriesByCourierQuery();

  const navigate = useNavigate();

  const viewRequestDetails = (item_id: string | number) => {
    navigate(`/courier/verification-code/${item_id}`);
  };

  const acceptedDeliveries = data?.filter(
    (delivery) =>
      delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER ||
      delivery.deliveryStatus === DeliveryStatus.PICKED_UP ||
      delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER
  );

  const completedDeliveries = data?.filter(
    (delivery) => delivery.deliveryStatus === DeliveryStatus.DELIVERED
  );

  return (
    <AppLayout>
      {isLoading || !acceptedDeliveries || !completedDeliveries ? (
        <Loader />
      ) : acceptedDeliveries.length === 0 || completedDeliveries.length === 0 ? (
        <div className="flex h-full w-full flex-col">
          <div className="flex items-center justify-between p-4">
            <h4 className="text-gray-700">VCode</h4>
            <ListFilter />
          </div>
          <div className="flex flex-1 flex-col items-center justify-center p-4">
            <div className="flex flex-col items-center">
              <StickyNote size={50} />
              <h4 className="mb-4 mt-6 opacity-80">Here is empty</h4>
              <p className="mb-4 text-center text-xs font-semibold opacity-60">
                See pioneers requesting for delivery in your region
              </p>
              <Button
                className="flex w-full items-center justify-center gap-1 whitespace-nowrap border-pi-primary-default text-base-white"
                onClick={() => {
                  navigate('/courier');
                }}
              >
                Pickup a delivery
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex h-full w-full flex-col ">
          <div className="flex items-center justify-between bg-pi-primary-default p-4">
            <h4 className="text-base-white">VCode</h4>
            <ListFilter color="white" />
          </div>
          <div className="flex flex-col gap-4 overflow-auto p-4 ">
            <div className="flex flex-col items-center gap-4 ">
              <div className="flex w-[100%] items-center justify-between ">
                <p className="text-sm">Accepted Deliveries</p>
                <span className="text-sm opacity-30">Today</span>
              </div>

              {acceptedDeliveries.length === 0 ? (
                <div>
                  <div className="flex w-full flex-col items-center justify-center">
                    {' '}
                    You have not accepted any delivery
                  </div>
                </div>
              ) : (
                acceptedDeliveries.map((item) => {
                  return (
                    <Card
                      className="flex w-[100%] flex-1 flex-row gap-1 space-x-3 rounded-lg bg-base-white p-2 text-sm shadow-sm"
                      key={item.id}
                      onClick={() => viewRequestDetails(item.id)}
                    >
                      <div className="flex w-2/12 items-center justify-center overflow-hidden rounded-lg border">
                        <img alt={item.itemName} className="object-cover " src={item.itemImage} />
                      </div>
                      <div className="flex flex-1 flex-col">
                        <div className="flex items-center justify-between">
                          <p>{item.itemName}</p>
                          <span
                            className={`flex items-center gap-1 text-xs font-semibold ${
                              item.deliveryStatus === DeliveryStatus.PICKED_UP
                                ? 'opacity-50'
                                : 'text-pi-primary-default'
                            } `}
                          >
                            Verify Package
                            <ChevronRight size={12} />
                          </span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="text-sm opacity-70">{item.id}</span>
                          <span className="text-xs opacity-40">
                            {format(
                              item.deliveryStatus === DeliveryStatus.ACCEPTED
                                ? item.createdAt
                                : item.pickupDate,
                              'h:mm aaa'
                            )}
                          </span>
                        </div>
                        {item.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER ? (
                          <span className="w-min rounded  bg-purple-100 px-2 py-1 text-xs text-pi-primary-default">
                            Pickup
                          </span>
                        ) : (
                          <span
                            className="
                        w-min text-nowrap rounded bg-amber-100 
                        px-2 py-1 text-xs
                        text-pi-secondary-default"
                          >
                            Drop off
                          </span>
                        )}
                      </div>
                    </Card>
                  );
                })
              )}
            </div>
            <div className="flex flex-col items-center gap-4">
              <div className="flex w-full items-center justify-between">
                <p className="text-sm">Delivered</p>
              </div>

              {completedDeliveries.map((item) => {
                return (
                  <Card
                    className="flex w-full flex-row gap-1 space-x-3 rounded-lg bg-base-white p-2 text-sm shadow-sm"
                    key={item.id}
                    onClick={() => viewRequestDetails(item.id)}
                  >
                    <div className="flex w-2/12 items-center justify-center overflow-hidden rounded-lg border">
                      <img alt={item.itemName} className="object-cover " src={item.itemImage} />
                    </div>
                    <div className="flex flex-1 flex-col">
                      <div className="flex items-center justify-between">
                        <p>{item.itemName}</p>
                        <span className="text-xs opacity-40">
                          {format(item.deliveryDate, 'h:mm aaa')}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="text-sm opacity-70">{item.id}</span>
                      </div>
                      <span
                        className="
                        w-min text-nowrap rounded bg-green-100 
                        px-2 py-1 text-xs
                        text-green-800"
                      >
                        Delivered
                      </span>
                    </div>
                  </Card>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </AppLayout>
  );
};

export default VerificationCode;
