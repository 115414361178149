import { Button } from '@/ui/button';
import { Check } from 'lucide-react';
import { motion } from 'framer-motion';

import { Link, useLocation, useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const { state } = useLocation();

  const navigate = useNavigate();

  const mode = state.mode;
  const deliveryID = state.deliveryID;

  const goToHome = () => {
    if (mode === 'staking') {
      navigate('/courier');
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {mode === 'staking' ? (
        <div className="flex h-[100dvh] w-[100vw] flex-col gap-4 p-4">
          <div className="flex justify-end">
            <Button fill="ghost" onClick={goToHome}>
              Done
            </Button>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-4 ">
            <motion.div
              animate={{ scale: 1, opacity: 1 }}
              className="my-4 flex items-center justify-center"
              initial={{ scale: 0, opacity: 0 }}
            >
              <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-green-50 ">
                <Check color="#039855" size={45} />
              </div>
            </motion.div>
            <h4 className="text-center text-xl">Staking Payment Successful! </h4>
            <p
              className="w-[70%]
      self-center text-center text-sm text-gray-600
      "
            >
              You have successfully been registered as a courier. <br /> Check your dashboard to see
              your details.
            </p>
          </div>
          <div
            className="
      flex w-[100%]  items-center justify-center gap-4
      "
          >
            <Link
              className="w-[100%]"
              to="/courier/dashboard"
            >
              <Button className="w-[100%]">Go to Dashboard</Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex h-[100dvh] w-[100vw] flex-col gap-4 p-4">
          <div className="flex justify-end">
            <Button fill="ghost" onClick={goToHome}>
              Done
            </Button>
          </div>
          <div className="flex flex-1 flex-col items-center justify-center gap-4 ">
            <motion.div
              animate={{ scale: 1, opacity: 1 }}
              className="my-4 flex items-center justify-center"
              initial={{ scale: 0, opacity: 0 }}
            >
              <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-green-50 ">
                <Check color="#039855" size={45} />
              </div>
            </motion.div>
            <h4 className="text-center text-xl">Transaction Successful!</h4>
            <p
              className="w-[70%]
      self-center text-center text-sm text-gray-600
      "
            >
              Your transaction has been completed successfully
            </p>
          </div>
          <div
            className="
      flex w-[100%] flex-col  items-center justify-center gap-4
      "
          >
            <Link className="w-[100%]" to="/sender">
              <Button
                className="w-[100%] hover:bg-gray-200 hover:text-pi-primary-default"
                fill="outline"
              >
                Create another delivery
              </Button>
            </Link>
            <Link
              className="w-[100%]"
              state={{
                deliveryId: deliveryID,
              }}
              to="/receiver/timeline"
            >
              <Button className="w-[100%]">View Delivery</Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessPage;
