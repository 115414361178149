import { Bike } from '@/assets/icons';

import { Avatar, AvatarImage } from '@/ui/avatar';
import { Card } from '@/ui/card';
import { Button } from '@/ui/button';

import { Link } from 'react-router-dom';
import { ChevronRight, MapPin, Pi } from 'lucide-react';
import { cn } from '@/lib/utils';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import Dot from '@/components/dot';

type SentDeliveryCardProps = {
  imageUrl: string;
  itemName: string;
  deliveryLocation: string;
  senderImageUrl: string;
  senderUsername: string;
  deliveryStatus: string;
  price: number;
  deliveryId: string;
};

export default function SentDeliveryCard({
  imageUrl,
  itemName,
  deliveryLocation,
  senderImageUrl,
  senderUsername,
  deliveryStatus,
  price,
  deliveryId,
}: SentDeliveryCardProps) {
  const pendingdelivery =
    deliveryStatus === DeliveryStatus.CREATED ||
    deliveryStatus === DeliveryStatus.ACCEPTED ||
    deliveryStatus === DeliveryStatus.ASSIGNED;
  const activedelivery =
    deliveryStatus === DeliveryStatus.PICKED_UP ||
    deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER ||
    deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER;

  return (
    <Card
      className={cn('w-full gap-2 rounded-md bg-base-white px-3 py-2 pb-0 text-lg shadow-sm')}
      variant="custom"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center gap-3">
          <Avatar className="rounded-md">
            <AvatarImage src={imageUrl} />
          </Avatar>
          <div>
            <div className=" font-semibold">{itemName}</div>
          </div>
        </div>
        <Button asChild className=" flex items-center p-0 text-pi-primary-dark" fill="ghost">
          <Link state={{ deliveryId }} to="/receiver/timeline">
            <div className="font-semibold text-pi-primary-default">Track</div>
            <ChevronRight />
          </Link>
        </Button>
      </div>
      <div className="flex items-center gap-1 text-sm">
        <MapPin className="  text-2xl " size="16px" strokeWidth="1.5px" />
        <div className="w-[75%] text-left text-gray-700">{deliveryLocation}</div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-5">
          <Avatar className="h-6 w-6">
            <AvatarImage className="rounded-full bg-pi-primary-default" src={Bike} />
          </Avatar>
          <div className="flex items-center gap-1.5">
            <Avatar className="h-5 w-5 border">
              <AvatarImage src={senderImageUrl} />
            </Avatar>
            <div className="text-sm ">@{senderUsername}</div>
          </div>
        </div>

        <div className="flex items-center gap-1">
          {activedelivery ? (
            <>
              <span className="text-sm font-bold text-stone-500">In Transit</span>
              <Dot className=" h-2.5 w-2.5 bg-[#039855]" />
            </>
          ) : null}
          {pendingdelivery ? (
            <>
              <p className=" text-sm font-semibold text-stone-500">Pending</p>
              <Dot className="h-2.5 w-2.5 bg-[#FF9927]" />
            </>
          ) : null}
        </div>

        <div className="flex items-center font-medium text-pi-primary-default">
          <span>{price}</span>
          <Pi className="mt-1" size="14px" strokeWidth="2px" />
        </div>
      </div>
    </Card>
  );
}
