import React from "react";
import DeliveryItem from "./delivery-item";

const AcceptedDeliveries = () => {
  return (
    <div className="flex-1 ">
      <div
        className="
    flex flex-col gap-2 bg-amber-100 p-4"
      >
        <div
          className=" flex
        items-center justify-between text-base"
        >
          <p>Deliveries ready for transit</p>
          <span
            className="flex h-[20px] w-[20px] 
              items-center justify-center rounded-full   bg-red-700 text-xs text-base-white
              "
          >
            5
          </span>
        </div>
        <p className="text-xs">
          All pickup deliveries ready for shipment. Select the closest to
          location you wish to start
        </p>
      </div>
      <div
        className="
      flex flex-1 flex-col gap-4 p-4
      "
      >
        <h4 className="text-gray-600">On Transit</h4>

        <div className="flex flex-col gap-4 ">
          {/* //Item// */}
          <DeliveryItem onTransit />
        </div>
      </div>
      <div
        className="
      flex flex-1 flex-col gap-4 p-4 opacity-30
      "
      >
        <h4 className="text-gray-600">Start dropoff</h4>

        <div className="flex flex-col gap-4 ">
          {/* //Item// */}
          <DeliveryItem onTransit={false} />
          <DeliveryItem onTransit={false} />
          <DeliveryItem onTransit={false} />
          <DeliveryItem onTransit={false} />
        </div>
      </div>
    </div>
  );
};

export default AcceptedDeliveries;
