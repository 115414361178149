import { Frown } from 'lucide-react';
import DeliveryHeader from './components/delivery/delivery-page-header';
import { Tabs } from '@/ui/tabs';
import SentContent from './components/delivery/sent-content';
import ReceivedContent from './components/delivery/received-content';
import { useGetSenderDeliveryHistoryQuery } from '@/services/delivery.services';
import { Loader } from '@/components';
import AppLayout from '../app-layout/app-layout';
import EmptyMessage from '@/components/custom/empty-message';

import { Link } from 'react-router-dom';
import { Button } from '@/ui/button';

export default function SenderDeliveries() {
  const { data, isLoading } = useGetSenderDeliveryHistoryQuery();

  return (
    <AppLayout>
      <Tabs
        className="px-5 pb-5 pt-[110px] transition-all delay-150 ease-in-out"
        defaultValue="sent"
      >
        <DeliveryHeader />
        {isLoading ? (
          <Loader />
        ) : !data ? (
          <EmptyMessage className="mt-10" icon={Frown} message="Could not get deliveries">
            <Button asChild>
              <Link to="/sender">Back to home</Link>
            </Button>
          </EmptyMessage>
        ) : (
          <>
            <SentContent deliveryItems={data.sent} />
            <ReceivedContent deliveryItems={data.received} />
          </>
        )}
      </Tabs>
    </AppLayout>
  );
}
