/* eslint-disable react/no-array-index-key */
import { Button } from '@/ui/button';
import React, { useState } from 'react';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from '@/ui/sheet';
import { Check, X } from 'lucide-react';
import { userIcon } from '@/assets/images';
import { Loader } from '@/components';
import { motion } from 'framer-motion';
import {
  Delivery,
  useUpdateDeliveryStatusMutation,
  useVerifyReceiverDeliveryCodeMutation,
  useVerifySenderDeliveryCodeMutation,
} from '@/services/delivery.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { useNavigate } from 'react-router-dom';

const VCodeSection = ({ delivery }: { delivery: Delivery }) => {
  const navigate = useNavigate();
  const [verifyPickupCode, { isLoading: isVerifyingPickupCode }] =
    useVerifySenderDeliveryCodeMutation();
  const [verifyDeliveryCode, { isLoading: isVerifyingDeliveryCode }] =
    useVerifyReceiverDeliveryCodeMutation();
  const [updateDeliveryStatus, { isLoading: isUpdatingStatus }] = useUpdateDeliveryStatusMutation();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [bottomSheetState, setBottomSheetState] = useState<string | null>(null);

  const isSenderView = delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER;
  const isReceiverView = delivery.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER;

  const handleChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling instanceof HTMLInputElement) {
      element.nextSibling.focus();
    }
  };

  const handleVerifyPickupCode = async () => {
    const enteredOTP = otp.join('');
    try {
      const response = await verifyPickupCode({
        deliveryId: delivery.id,
        code: +enteredOTP,
      }).unwrap();
      setBottomSheetState(response.status);
    } catch (error) {
      console.error(error);
      setBottomSheetState('error');
    }
  };

  const handleVerifyDeliveryCode = async () => {
    const enteredOTP = otp.join('');
    try {
      const response = await verifyDeliveryCode({
        deliveryId: delivery.id,
        code: +enteredOTP,
      }).unwrap();
      setBottomSheetState(response.status);
    } catch (error) {
      console.error(error);
      setBottomSheetState('error');
    }
  };

  const returnToSender = async () => {
    try {
      await updateDeliveryStatus({
        deliveryId: delivery.id,
        status: DeliveryStatus.RETURNED,
      });
      navigate('/courier/verification-code');
    } catch (error) {
      console.error(error);
    }
  };

  if (!(isReceiverView || isSenderView || delivery.deliveryStatus === DeliveryStatus.DELIVERED)) {
    navigate('/courier/verification-code');
    return null;
  }

  return (
    <>
      {delivery.deliveryStatus === DeliveryStatus.DELIVERED && (
        <div className="sticky bottom-0 p-4 ">
          <Button className="flex w-full items-center justify-center whitespace-nowrap border-pi-primary-default  text-base-white opacity-40">
            Delivery Completed
          </Button>
        </div>
      )}
      {delivery.deliveryStatus !== DeliveryStatus.DELIVERED && (
        <Sheet>
          <SheetTrigger className="sticky bottom-0 p-4">
            <Button className="flex w-full items-center justify-center whitespace-nowrap border-pi-primary-default text-base-white">
              {}
              Verify {isReceiverView === true && "Receiver's"}
              {isSenderView === true && "Sender's"} Code
            </Button>
          </SheetTrigger>
          {bottomSheetState === null && (
            <SheetContent
              className="rounded-t-3xl  bg-base-white px-5 pb-4 pt-5 font-futura "
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="font-futura text-xl font-light  ">
                  Enter {isReceiverView === true && "Receiver's"}{' '}
                  {isSenderView === true && "Sender's"} Confirmation Code
                </SheetTitle>
              </SheetHeader>
              <SheetDescription className="mt-5 justify-center text-center text-sm text-gray-400">
                Code becomes active when your Pailot is closer to pickup location
              </SheetDescription>

              <div className="mt-4 flex w-[100%] items-center justify-between gap-2">
                {otp.map((data, index) => {
                  return (
                    <input
                      className={`h-[10vw] w-[10vw] border border-base-black/[.2] text-center text-pi-primary-default ${
                        otp[index] === '' && 'opacity-30'
                      } text-xl font-medium `}
                      inputMode="numeric"
                      key={`otp-${index}`}
                      maxLength={1}
                      name="otp"
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                      title="OTP"
                      type="text"
                      value={data}
                    />
                  );
                })}
              </div>
              <SheetDescription className="mt-5 justify-center text-center text-xs text-gray-400">
                *You will be charged 0% for platform fee
              </SheetDescription>
              <Button
                className=" text-m mt-2 flex w-full items-center justify-center border-2 border-pi-primary-default text-base-white "
                fill="default"
                onClick={() => {
                  if (isReceiverView === true) {
                    handleVerifyDeliveryCode();
                  }
                  if (isSenderView === true) {
                    handleVerifyPickupCode();
                  }
                }}
              >
                Verify Code
              </Button>
            </SheetContent>
          )}
          {isVerifyingDeliveryCode ||
            (isVerifyingPickupCode && (
              <SheetContent
                className="rounded-t-3xl  bg-base-white px-5 pb-4 pt-5 font-futura "
                side="bottom"
              >
                <SheetHeader>
                  <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                  <SheetTitle className="font-futura text-xl font-light  ">
                    Confirming Package
                  </SheetTitle>
                </SheetHeader>
                <div className="flex h-[30dvh] w-[100%] items-center justify-center">
                  <Loader className="bg-transparent" />
                </div>
                <Button
                  className=" text-m mt-2 flex w-full items-center justify-center border-2 border-pi-primary-default text-base-white "
                  fill="default"
                >
                  Please Wait...
                </Button>
              </SheetContent>
            ))}

          {isUpdatingStatus === true && (
            <SheetContent>
              <div className="flex h-[30dvh] w-[100%] items-center justify-center">
                <Loader className="bg-transparent" />
              </div>
            </SheetContent>
          )}
          {bottomSheetState === 'success' && isSenderView === true && (
            <SheetContent
              className="flex flex-col items-center rounded-t-3xl bg-base-white px-5 pb-4 pt-5 font-futura"
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="text-medium font-futura font-medium  ">
                  Code Verified
                </SheetTitle>
              </SheetHeader>
              <div className="my-4 flex items-center justify-center">
                <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-green-100 ">
                  <Check color="#039855" size={45} />
                </div>
              </div>
              <SheetDescription className="mt-5 justify-center text-center text-lg  font-extralight text-base-black opacity-80">
                Package pickup for @{delivery.senderProfile.user.username}
              </SheetDescription>
              <motion.div
                className="relative flex h-[50px] w-[90px] items-center self-center "
                initial={{ width: '50%', opacity: 0 }}
                whileInView={{ width: 90, opacity: 1 }}
              >
                <img
                  alt="item"
                  className="absolute right-0
                  aspect-square w-[50px] 
                  rounded-full bg-zinc-300
                  "
                  src={delivery.itemImage}
                />
                <img
                  alt="item"
                  className=" absolute left-0 aspect-square w-[50px] rounded-full bg-zinc-300"
                  src={delivery.senderProfile.user.profileImgUrl || userIcon}
                />
              </motion.div>
              <SheetClose className="w-full">
                <Button
                  className=" text-m mt-2 flex w-full items-center justify-center border-2 border-pi-primary-default text-base-white "
                  fill="default"
                >
                  Close
                </Button>
              </SheetClose>
            </SheetContent>
          )}
          {bottomSheetState === 'success' && isReceiverView === true && (
            <SheetContent
              className="flex  flex-col items-center rounded-t-3xl bg-base-white px-5 pb-4 pt-5 font-futura "
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="text-medium font-futura font-medium  ">
                  Code Verified
                </SheetTitle>
              </SheetHeader>
              <div className="my-4 flex items-center justify-center">
                <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-green-100 ">
                  <Check color="#039855" size={45} />
                </div>
              </div>
              <SheetDescription className="mt-5 w-[50%] justify-center text-center  text-lg font-extralight text-base-black opacity-80">
                Package matched with @{delivery.receiverProfile.user.username}
              </SheetDescription>
              <motion.div
                className="relative flex h-[50px] w-[90px] items-center self-center "
                initial={{ width: '50%', opacity: 0 }}
                whileInView={{ width: 90, opacity: 1 }}
              >
                <img
                  alt="item"
                  className="absolute right-0
                  aspect-square w-[50px] 
                  rounded-full bg-zinc-300
                  "
                  src={delivery.itemImage}
                />
                <img
                  alt="item"
                  className=" absolute
                  left-0 aspect-square 
                  w-[50px] rounded-full bg-zinc-300
                  "
                  src={delivery.receiverProfile.user.profileImgUrl || userIcon}
                />
              </motion.div>
              <SheetClose className="w-full">
                <Button
                  className=" text-m mt-2 flex w-full items-center justify-center border-2 border-pi-primary-default text-base-white "
                  fill="default"
                >
                  Close
                </Button>
              </SheetClose>
            </SheetContent>
          )}
          {bottomSheetState === 'error' && isSenderView === true && (
            <SheetContent
              className="flex  flex-col items-center rounded-t-3xl bg-base-white px-5 pb-4 pt-5 font-futura "
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="text-medium font-futura font-medium  ">
                  Code Invalid
                </SheetTitle>
              </SheetHeader>
              <div className=" flex items-center justify-center">
                <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-red-50 ">
                  <X color="#cc0000" size={45} />
                </div>
              </div>
              <SheetDescription className="mt-5  justify-center text-center  text-lg font-extralight text-rose-700 ">
                Package did not match with this <br />
                Sender
              </SheetDescription>
              <motion.div
                className="relative flex h-[50px] w-[90px] items-center self-center "
                initial={{ width: 90, opacity: 0 }}
                whileInView={{ width: '50%', opacity: 1 }}
              >
                <img
                  alt="item"
                  className="absolute right-0 aspect-square w-[50px] rounded-full bg-zinc-300"
                  src={delivery.itemImage}
                />
                <img
                  alt="item"
                  className="absolute left-0 aspect-square w-[50px] rounded-full bg-zinc-300"
                  src={delivery.senderProfile.user.profileImgUrl || userIcon}
                />
              </motion.div>
              <div className="w-full">
                <Button
                  className=" text-m mt-2 flex w-full items-center justify-center border-2 border-pi-primary-default text-base-white "
                  fill="default"
                  onClick={() => {
                    setBottomSheetState(null);
                    setOtp(new Array(6).fill(''));
                  }}
                >
                  Try Again
                </Button>
                <Button
                  className="text-m mt-4 flex w-full items-center justify-center border-2 border-pi-primary-default text-pi-primary-default "
                  fill="outline"
                >
                  Return to Sender
                </Button>
              </div>
            </SheetContent>
          )}
          {bottomSheetState === 'error' && isReceiverView === true && (
            <SheetContent
              className="flex flex-col items-center rounded-t-3xl bg-base-white px-5 pb-4 pt-5 font-futura "
              side="bottom"
            >
              <SheetHeader>
                <div className="mx-auto mb-7 h-1 w-12 rounded-full bg-gray-300" />
                <SheetTitle className="text-medium font-futura font-medium  ">
                  Code Invalid
                </SheetTitle>
              </SheetHeader>
              <div className="flex items-center justify-center">
                <div className="flex aspect-square w-[20vw] items-center justify-center rounded-full bg-red-50 ">
                  <X color="#cc0000" size={45} />
                </div>
              </div>
              <SheetDescription className="mt-5  justify-center text-center  text-lg font-extralight text-rose-700 ">
                Package did not match with this <br />
                Receiver
              </SheetDescription>
              <motion.div
                className="relative flex h-[50px] w-[90px] items-center self-center"
                initial={{ width: 90, opacity: 0 }}
                whileInView={{ width: '50%', opacity: 1 }}
              >
                <img
                  alt="item"
                  className="absolute right-0 aspect-square w-[50px] rounded-full bg-zinc-300"
                  src={delivery.itemImage}
                />
                <img
                  alt="item"
                  className="absolute left-0 aspect-square w-[50px] rounded-full bg-zinc-300"
                  src={delivery.receiverProfile.user.profileImgUrl || userIcon}
                />
              </motion.div>
              <div className="w-full">
                <Button
                  className=" text-m mt-2 flex w-full items-center justify-center border-2 border-pi-primary-default text-base-white "
                  fill="default"
                  onClick={() => {
                    setBottomSheetState(null);
                    setOtp(new Array(6).fill(''));
                  }}
                >
                  Try Again
                </Button>
                <Button
                  className="text-m mt-4 flex w-full items-center justify-center border-2 border-pi-primary-default text-pi-primary-default"
                  disabled={isUpdatingStatus}
                  fill="outline"
                  onClick={returnToSender}
                >
                  Return to Sender
                </Button>
              </div>
            </SheetContent>
          )}
        </Sheet>
      )}
    </>
  );
};

export default VCodeSection;
