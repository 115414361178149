import { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { useMachine } from "@xstate/react";
import { deliveryFormMachine } from "./state-machine/state-machine";
import ConfirmReceiverForm from "./components/confirm-receiver-form";
import DescribeItemForm from "./components/describe-item-form";
import DeliveryLocationForm from "./components/delivery-location-form";
import ReviewDeliveryDetailsForm from "./components/review-delivery-details-form";
import DeliveryPriceForm from "./components/delivery-price-form";
import DeliverySubmitResult from "./components/delivery-submit-result";
import { useCreateDeliveryMutation } from "@/services/delivery.services";
import Loader from "../loader/loader";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import ToastedAlert from "../custom/ToastedAlert";

export type DeliveryFormData = {
  receiverName: string;
  receiverUid: string;
  itemName: string;
  itemDescription: string;
  itemCategory: string;
  pickupAddress: string;
  dropOffAddress: string;
  modeOfDelivery: string[];
  itemImage: FileList | null;
  price: number;
  isItemPackaged: boolean;
  isItemSealed: boolean;
};

function DeliveryForm() {
  const { state: routerState } = useLocation();
  const [state, send] = useMachine(deliveryFormMachine);
  const [createDelivery, { isLoading }] = useCreateDeliveryMutation();
  const form = useForm<DeliveryFormData, any, DeliveryFormData>({
    defaultValues: {
      receiverName: "",
      receiverUid: "",
      itemName: "",
      itemDescription: "",
      itemCategory: "",
      pickupAddress: "",
      dropOffAddress: "",
      modeOfDelivery: [],
      itemImage: null,
      price: undefined,
      isItemPackaged: false,
      isItemSealed: false,
    },
    mode: "all",
  });
  const [receiversUsername, setReceiversUsername] = useState("");
  const [deliveryID, setDeliveryID] = useState("");

  useEffect(() => {
    if (routerState && routerState.courierPrice && routerState.courierUid) {
      send({
        type: "UPDATE",
        value: { ...state.context, isCourierHired: true },
      });
    }
  }, [routerState]);

  const onSubmit: SubmitHandler<DeliveryFormData> = async (
    data: DeliveryFormData,
  ) => {
    try {
      if (data.price && data.price <= 0) {
        toast.error(
          <ToastedAlert
            description="Delivery price cannot be zero"
            title="Error"
          />,
        );
        return;
      }
      const formData = new FormData();
      formData.append("receiverUid", data.receiverUid);
      formData.append("itemName", data.itemName);
      formData.append("itemDescription", data.itemDescription);
      formData.append("itemCategory", data.itemCategory);
      formData.append("fromAddress", data.pickupAddress);
      formData.append("toAddress", data.dropOffAddress);

      if (routerState && routerState.modesOfDelivery) {
        formData.append(
          "preferredModeOfDelivery",
          routerState.modesOfDelivery.join(","),
        );
      } else {
        formData.append(
          "preferredModeOfDelivery",
          data.modeOfDelivery.join(","),
        );
      }

      if (routerState && routerState.courierUid) {
        formData.append("courierUid", routerState.courierUid);
      }
      if (routerState && routerState.courierPrice) {
        formData.append("transactionAmount", `${routerState.courierPrice}`);
      } else {
        formData.append("transactionAmount", `${data.price}`);
      }
      if (data.itemImage) {
        formData.append("itemImage", data.itemImage[0] as File);
      }
      const delivery = await createDelivery(formData).unwrap();
      setDeliveryID(delivery.id);
      setReceiversUsername(data.receiverName);

      send({ type: "NEXT" });
    } catch (error) {
      console.error(error);
      if (state.context.step > 1) {
        toast.error(
          <ToastedAlert
            description="An error occurred while creating the delivery, Try again!"
            title="Error"
          />,
        );
      }
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="h-[100dvh] w-full overflow-hidden">
      <FormProvider {...form}>
        <form
          onSubmit={(event) => {
            // We need to clear the errors because react hook form will refuse to submit the form if there are errors
            form.clearErrors();
            return form.handleSubmit(onSubmit)(event);
          }}
        >
          {state.matches("step1") && (
            <ConfirmReceiverForm form={form} send={send} state={state} />
          )}
          {state.matches("step2") && (
            <DescribeItemForm form={form} send={send} state={state} />
          )}
          {state.matches("step3") && (
            <DeliveryLocationForm
              form={form}
              hasCourierDeliveryModes={
                routerState?.modesOfDelivery !== undefined
              }
              send={send}
              state={state}
            />
          )}
          {state.matches("step4") && (
            <DeliveryPriceForm form={form} send={send} state={state} />
          )}
          {state.matches("submit") && (
            <ReviewDeliveryDetailsForm
              form={form}
              isCourierHired={routerState?.courierPrice !== undefined}
              send={send}
              state={state}
            />
          )}
          {state.matches("success") && (
            <DeliverySubmitResult
              deliveryID={deliveryID}
              isCustomDelivery={routerState ? false : true}
              receiversUsername={receiversUsername}
            />
          )}
        </form>
      </FormProvider>
    </div>
  );
}

export default DeliveryForm;
