import React from 'react';
import { slippers, userIcon } from '@/assets/images';
import { Button } from '@/ui/button';
import { Ellipsis } from '@/assets/icons';

interface Props {
  onTransit: boolean;
}

const DeliveryItem: React.FC<Props> = ({ onTransit }) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-base-white p-4">
      <div className="flex items-center justify-between gap-4">
        <img
          alt="Item"
          className="h-[30px] w-[30px] rounded-lg border border-gray-300 object-cover"
          src={slippers}
        />
        <p className=" flex-1 text-sm">Crocs Slippers</p>

        <span className="text-sm font-semibold text-pi-primary-default">0.00034π</span>
      </div>
      <div className="flex items-center justify-between gap-2">
        <img alt="sender icon" className="h-[20px] w-[20px] rounded-full" src={userIcon} />
        <p className="flex-1 text-xs">@piusername</p>
        <span className={`text-xs ${onTransit ? 'text-green-600' : 'text-amber-900'}`}>
          {onTransit ? 'On transit' : 'Awaiting'}
        </span>
      </div>
      <div
        className="
          flex gap-4"
      >
        <div className="flex flex-col">
          <span className="text-xs">Sender Location</span>
          <p className="text-sm font-semibold">2464 Royal Ln. Mesa, New Jersey 45463</p>
        </div>{' '}
        <div className="flex flex-col">
          <span className="text-xs">Receiver Location</span>
          <p className="text-sm font-semibold">2464 Royal Ln. Mesa, New Jersey 45463</p>
        </div>
      </div>
      <div
        className="
          flex gap-4"
      >
        {onTransit ? (
          <Button className="flex-1 bg-green-600 hover:bg-green-600">Delivery in progress</Button>
        ) : (
          <Button className="flex-1">Start this Delivery</Button>
        )}

        <Button
          className="flex w-[35%] items-center justify-center gap-2 bg-pi-secondary-default hover:bg-pi-secondary-default"
          disabled={onTransit === false}
        >
          More
          <img alt="icon" className="h-[18px]" src={Ellipsis} />
        </Button>
      </div>
    </div>
  );
};

export default DeliveryItem;
