// User Endpoints
export const SIGN_IN = `/users/signin`; // method: POST body: { accessToken: string, user: { uid: string, username: string } }
export const SWITCH_PROFILE = `/users/switch-profile`; // method: PUT body: { profileName: 'sender' | 'courier' }
export const GET_RECEIVER_BY_USERNAME = (username: string) =>
  `/users/receiver-username/${username}`; // method: GET
export const GET_SENDER_BY_USER_UID = (userUid: string) =>
  `/users/sender/${userUid}`; // method: GET
export const GET_COURIERS_NEAR_SENDER = `/users/get-couriers-near-sender`; // method: GET
export const UPLOAD_PROFILE_IMAGE = `/users/update-profile-image`; // method: PUT body: formData: { profileImg: File }
export const UPDATE_USER_LOCATION = `/users/update-location`; // method: PUT body: formData: { profileImg: File }
export const REMOVE_PROFILE_IMAGE = `/users/remove-profile-image`; // method: PUT
export const UPDATE_ACCESS_TOKEN = `/users/update-access-token`; // method: PUT body: { accessToken }

// Courier Endpoints
export const CREATE_COURIER_PROFILE = `/couriers`; // method: POST body: { city: string, state: string, country: string, preferredDeliveryAmount: number, stakeAmount: number, modesOfDelivery: string }
export const GET_COURIER_PROFILE = `/couriers/profile`; // method: GET
export const GET_COURIER_PROFILE_BY_ID = (id: string) =>
  `/couriers/profile/${id}`; // method: GET
export const UPDATE_COURIER_EARNINGS = `/couriers/earnings`; // method: PUT body: { earnings: number }
export const UPDATE_COURIER_AVAILABILITY = `/couriers/availability`; // method: PUT body: { isActive: boolean }
export const UPDATE_COURIER_DELIVERY_AMOUNT = `/couriers/delivery-amount`; // method: PUT body: { deliveryAmount: number }
export const UPDATE_COURIER_DELIVERY_REGION = `/couriers/delivery-region`; // method: PUT body: { address: string, addressObject: { city: string, state: string, country: string } }
export const UPDATE_COURIER_MODES_OF_DELIVERY = `/couriers/modes-of-delivery`; // method: PUT body: { modesOfDelivery: string }

// Delivery Endpoints
export const GET_DELIVERIES = `/delivery`; // method: GET

export const GET_DELIVERY_BY_ID = (id: string) => `/delivery/${id}`; // method: GET

export const GET_DELIVERY_BY_SENDER = (senderUid: string) =>
  `/delivery/sender/${senderUid}`; // method: GET

export const GET_DELIVERY_BY_RECEIVER = (receiverUid: string) =>
  `/delivery/receiver/${receiverUid}`; // method: GET

export const GET_SENDER_DELIVERY_HISTORY = `/delivery/sender/delivery-history`; // method: GET

export const GET_DELIVERIES_NEAR_COURIER = `/delivery/courier/delivery-near-me`; // method: GET

export const ACCEPT_DELIVERY = (deliveryId: string) =>
  `/delivery/courier/accept-delivery/${deliveryId}`; // method: POST

// List of delivery requests (Accepted deliveries by courier or Hired deliveries by sender)
export const GET_COURIER_ACCEPTED_DELIVERIES = `/delivery/courier/accepted-deliveries`; // method: GET

// Creating a delivery requires a multipart/form-data request
export const CREATE_DELIVERY = `/delivery`; // method: POST body(formData): { itemImage: File, courierUid?: string; receiverUid: string; senderUid: string; preferredModeOfDelivery: string; transactionAmount: number; fromAddress: string; toAddress: string; itemName: string; itemDescription: string; paymentId: string; itemCategory: string; }

// Updating a delivery if a user picks up the delivery
export const UPDATE_DELIVERY = (id: string) => `/delivery/${id}`; // method: PUT body: { courierUid: string }

// Updating a delivery status
export const UPDATE_DELIVERY_STATUS = (deliveryId: string) =>
  `/delivery/update-status/${deliveryId}`; // method: PUT body: { status: string }

export const UPDATE_DELIVERY_PAYMENT = (
  deliveryId: string,
  paymentId: string,
) => `/delivery/update-delivery-amount/${deliveryId}/${paymentId}`; // method: PUT

export const VERIFY_RECEIVER_DELIVERY_CODE = "/delivery/receiver/verify-code"; // method: POST body: { deliveryId: string, code: number }

export const VERIFY_SENDER_DELIVERY_CODE = "/delivery/sender/verify-code"; // method: POST body: { deliveryId: string, code: number }

export const GET_DElIVERIES_FOR_COUIRER = `/delivery/courier`; // method: GET

// Payments Endpoints
export const INCOMPLETE_PAYMENT = `/payment/incomplete`;
export const APPROVE_PAYMENT = `/payment/approve`;
export const COMPLETE_PAYMENT = `/payment/complete`;
export const CANCELLED_PAYMENT = `/payment/cancel`;
export const MAKE_WITHDRAWAL = `/payment/courier/withdraw`;

// Stake Payments Endpoints
export const APPROVE_STAKE_PAYMENT = `/stake/approve`;
export const COMPLETE_STAKE_PAYMENT = `/stake/complete`;
export const CANCELLED_STAKE_PAYMENT = `/stake/cancel`;

// Chat Endpoints
export const GET_CHATS_FOR_USER = `/chat/user`; // method: GET
export const GET_CHAT_BY_ID = (id: string) => `/chat/${id}`; // method: GET
export const GET_CHAT_MESSAGES = (chatId: string) =>
  `/chat/messages/chat/${chatId}`; // method: GET
export const ARCHIVE_CHAT = (id: string) => `/chat/${id}`; // method: PUT
export const MARK_MESSAGE_AS_READ = (id: string) => `/chat/read-message/${id}`; // method: PUT
export const GET_UNREAD_MESSAGES = (chatId: string) =>
  `/chat/unread-messages/chat/${chatId}`; // method: GET

// Review Endpoints
export const CREATE_REVIEW = `/review`; // method: POST body: { courierUid: string, rating: number, comment: string }
