import React, { useState } from 'react';
import { Info } from 'lucide-react';
import Stake from './stake';
import { Props } from './state-machine/courier-setup-machine';
import { UseFormReturn } from 'react-hook-form';
import { CourierSetupForm } from './setup-form';
import FormHeader from '@/components/delivery-form/components/form-header';
import { Button } from '@/ui/button';
import { FormField, FormItem, FormMessage } from '@/ui/form';

const DeliveryStaking = ({
  send,
  state,
  form,
}: Props & {
  form: UseFormReturn<CourierSetupForm, any, CourierSetupForm>;
}) => {
  const [activeStake, setActiveStake] = useState<{
    name: string;
    amount: number;
    features: string[];
    primaryBgColor: string;
  } | null>(null);
  const stakePlans = [
    {
      name: 'starter',
      amount: 5,
      features: [
        'From  0 - 50 deliveries',
        'Only pick packages below 10pi',
        'Withdrawable anytime',
      ],
      primaryBgColor: 'bg-pi-primary-default',
    },
    {
      name: 'premium',
      amount: 7,
      features: [
        'From 51 - 150 deliveries',
        'Only pickup packages below Starter Stake 50pi',
        'Withdrawable anytime',
      ],
      primaryBgColor: 'bg-pi-accent-c',
    },
    {
      name: 'vip',
      amount: 10,
      features: [
        'From 151 - 600 deliveries',
        'Include packages with Starter and Premium',
        'Withdrawable anytime',
      ],
      primaryBgColor: 'bg-pi-secondary-dark',
    },
  ];

  const activeStakeHandler = (stake: string) => {
    const currentStake = stakePlans.find((s) => s.name === stake);
    if (!currentStake) return;
    if (activeStake?.name === currentStake.name) {
      setActiveStake(null);
    } else {
      setActiveStake(currentStake);
    }
  };

  return (
    <div className="h-screen overflow-auto">
      <div className="p-4 pb-0">
        <FormHeader
          description=""
          onGoBack={() => send({ type: 'PREVIOUS' })}
          step={state.context.step}
          title="Delivery Staking"
        />
      </div>
      <p className="p-6 text-gray-600">
        Stake some Pi to be able to access deliveries ads within your region. Pick a plan that suits
        your delivery capacity and region. <br /> Stakes can be removed at after 30 days of staking
        and when you don&apos;t have any active delivery.
      </p>
      {activeStake !== null && (
        <div
          className="flex
        flex-col
      gap-4
      border-b border-gray-500 p-6
      "
        >
          <h3>Active Stake</h3>

          <Stake {...activeStake} key={activeStake.name} selected />
        </div>
      )}

      <div className="flex flex-col gap-4 p-6">
        <h3 className="flex items-center justify-between ">
          Stake Insurance
          <Info />
        </h3>
        <FormField
          name="stakingAmount"
          render={() => (
            <FormItem>
              <div className="grid grid-cols-2 gap-4">
                {stakePlans.map((stake: any) => {
                  if (activeStake?.name !== stake.name) {
                    return (
                      <Button
                        className="h-full min-w-40 p-0 hover:bg-transparent active:bg-transparent"
                        fill="ghost"
                        key={stake.name}
                        onClick={() => {
                          form.setValue('stakingAmount', stake.amount, {
                            shouldValidate: true,
                          });
                          activeStakeHandler(stake.name);
                        }}
                      >
                        <Stake {...stake} selected={false} />
                      </Button>
                    );
                  }
                })}
              </div>

              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};

export default DeliveryStaking;
