import { TabsContent } from '@/ui/tabs';
import SentDeliveryCard from './sent-delivery-card';

import EmptyMessage from '@/components/custom/empty-message';
import { ShoppingBag } from 'lucide-react';
import { userIcon } from '@/assets/images';
import { Button } from '@/ui/button';
import { Delivery } from '@/services/delivery.services';
import { DeliveryStatus } from '@/types/pailot-interfaces';
import { Link } from 'react-router-dom';

export default function SentContent({ deliveryItems }: { deliveryItems: Delivery[] | undefined }) {
  const activeDeliveries = deliveryItems?.filter(
    (item) =>
      item.deliveryStatus === DeliveryStatus.PICKED_UP ||
      item.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_SENDER ||
      item.deliveryStatus === DeliveryStatus.IN_TRANSIT_TO_RECEIVER
  );
  const activeRequests = deliveryItems?.filter(
    (item) =>
      item.deliveryStatus === DeliveryStatus.ACCEPTED ||
      item.deliveryStatus === DeliveryStatus.CREATED ||
      item.deliveryStatus === DeliveryStatus.ASSIGNED
  );

  return (
    <TabsContent className="transition-all delay-150 ease-in-out" value="sent">
      <section className="flex flex-col justify-center">
        {deliveryItems && deliveryItems?.length > 0 ? (
          <div className="flex flex-col gap-3">
            {activeDeliveries && activeDeliveries.length > 0 ? (
              <>
                <div className="flex items-center justify-between">
                  <h5 className="font-medium  text-gray-500">Active deliveries</h5>
                  <span className="text-xs text-stone-400">Happening now</span>
                </div>
                {activeDeliveries.map((item) => (
                  <SentDeliveryCard
                    deliveryId={item.id}
                    deliveryLocation={item.toAddress}
                    deliveryStatus={item.deliveryStatus}
                    imageUrl={item.itemImage}
                    itemName={item.itemName}
                    key={item.id}
                    price={item.transactionAmount}
                    senderImageUrl={item.senderProfile.user.profileImgUrl || userIcon}
                    senderUsername={item.senderProfile.user.username}
                  />
                ))}
              </>
            ) : null}

            {activeRequests && activeRequests.length > 0 ? (
              <>
                <div className="flex items-center justify-between">
                  <h5 className="font-medium  text-gray-500">Active requests</h5>
                  <span className="text-xs text-stone-400">Yesterday</span>{' '}
                  {/** TODO: What is the meaning of this and how do we get the data? */}
                </div>
                {activeRequests.map((item) => (
                  <SentDeliveryCard
                    deliveryId={item.id}
                    deliveryLocation={item.toAddress}
                    deliveryStatus={item.deliveryStatus}
                    imageUrl={item.itemImage}
                    itemName={item.itemName}
                    key={item.id}
                    price={item.transactionAmount}
                    senderImageUrl={item.senderProfile.user.profileImgUrl || userIcon}
                    senderUsername={item.senderProfile.user.username}
                  />
                ))}
              </>
            ) : null}
          </div>
        ) : (
          <EmptyMessage icon={ShoppingBag} message="Oops! No delivery order sent yet">
            <div className="flex flex-col items-center justify-center gap-16">
              <p className="text-center text-xs">
                Your package will be shown here attached with your delivery Vcode
              </p>
              <Link to="/sender/new-delivery">
                <Button className="rounded-lg">Create a delivery ad</Button>
              </Link>
            </div>
          </EmptyMessage>
        )}
      </section>
    </TabsContent>
  );
}
