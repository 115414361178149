import { ListFilter } from 'lucide-react';
import { TabsList, TabsTrigger } from '@/ui/tabs';

export default function DeliveryHeader() {
  return (
    <header className="fixed left-0 top-0 z-[9999] ml-0 w-screen bg-base-white px-5 py-3">
      <h4 className="mb-4">Delivery</h4>
      <div className=" flex w-full items-center justify-between">
        <TabsList className="flex basis-[70%] justify-between  rounded-full border p-0 text-sm font-semibold">
          <TabsTrigger
            className="rounded-full px-4 py-1 data-[state=active]:border-0 data-[state=active]:bg-pi-primary-default data-[state=active]:text-stone-200"
            value="sent"
          >
            Sent items
          </TabsTrigger>
          <TabsTrigger
            className="rounded-full px-4 py-1 data-[state=active]:border-0 data-[state=active]:bg-pi-primary-default data-[state=active]:text-stone-200"
            value="received"
          >
            Received items
          </TabsTrigger>
        </TabsList>

        <ListFilter className="text-stone-600" />
      </div>
    </header>
  );
}
