import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/ui/form';
import { Input } from '@/ui/input';
import { Textarea } from '@/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/ui/select';
import { RiUploadCloud2Line } from 'react-icons/ri';
import FormHeader from './form-header';
import { Props } from '../state-machine/state-machine';
import { Button } from '@/ui/button';
import { UseFormReturn } from 'react-hook-form';
import { DeliveryFormData } from '../delivery-form';
import { useEffect, useState } from 'react';

function DescribeItemForm({
  send,
  state,
  form,
}: Props & {
  form: UseFormReturn<DeliveryFormData, any, DeliveryFormData>;
}) {
  const {
    formState: { isValid },
    register,
    watch,
  } = form;

  const selectedFile = watch('itemImage');

  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false);

  useEffect(() => {
    if (selectedFile && selectedFile[0]) {
      setIsImageUploaded(true);
    }
  }, [selectedFile]);

  return (
    <div className="flex h-[100dvh] flex-col justify-between gap-4 overflow-auto px-4 pb-4 ">
      <div className="flex-1 ">
        <FormHeader
          description="Upload picture, Item name, description and other related information."
          onGoBack={() => send({ type: 'PREVIOUS' })}
          step={state.context.step}
          title="Describe Item"
        />

        <div className="flex flex-col gap-5 pt-5 sm:pt-4">
          <FormField
            name="itemImage"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="relative block w-full cursor-pointer overflow-hidden rounded-lg bg-gray-300 hover:bg-gray-200">
                  {selectedFile && selectedFile[0] ? (
                    <div className="h-48">
                      <img
                        alt="uploaded file preview"
                        className="absolute inset-0 h-full w-full rounded-lg object-cover"
                        src={URL.createObjectURL(selectedFile[0])}
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40">
                        <div className="flex w-64 cursor-pointer flex-col items-center justify-center gap-2 rounded-full bg-[#1E1E1E] py-2 text-gray-50 hover:bg-[#1E1E1E]">
                          <RiUploadCloud2Line className="mr-2 h-5 w-5 text-gray-300" />
                          <span className="text-white text-sm">Edit image</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex w-full items-center justify-center bg-gray-200 py-10">
                      <div className="flex w-64 cursor-pointer flex-col items-center justify-center gap-2 rounded-full bg-[#1E1E1E] py-2 text-gray-50 hover:bg-[#1E1E1E]">
                        <RiUploadCloud2Line className="mr-2 h-5 w-5 text-gray-300" />
                        <span>Upload item picture</span>
                      </div>
                    </div>
                  )}
                  <FormControl>
                    <Input
                      accept="image/*"
                      className="absolute inset-0 h-full w-full cursor-pointer opacity-0"
                      onBlur={field.onBlur}
                      onChange={(e) => {
                        field.onChange(e.target.files);
                      }}
                      ref={field.ref}
                      type="file"
                    />
                  </FormControl>
                </FormLabel>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="itemName"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Name of item(s)</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    {...field}
                    {...register('itemName', {
                      required: 'Item name is required',
                    })}
                    placeholder="e.g: Taucci bag"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="itemDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Item(s) description</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    {...register('itemDescription', {
                      required: 'Item description is required',
                    })}
                    placeholder="e.g: Silk brown bag with suspended leather rope. Color brown and rough surface leather. "
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="itemCategory"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Category</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  value={field.value}
                  {...register('itemCategory', {
                    required: 'Item category is required',
                  })}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a category" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent position="item-aligned">
                    <SelectItem key="beauty and healthcare" value="beauty and healthcare">
                      Beauty and Healthcare
                    </SelectItem>
                    <SelectItem key="clothes and accesories" value="clothes and accesories">
                      Clothes and Accesories
                    </SelectItem>
                    <SelectItem key="consumer electronics" value="consumer electronics">
                      Consumer Electronics
                    </SelectItem>
                    <SelectItem key="electronics" value="electronics">
                      Electronics
                    </SelectItem>
                    <SelectItem key="foods and beverages" value="foods and beverages">
                      Foods and Beverages
                    </SelectItem>
                    <SelectItem key="household items" value="household items">
                      Household Items
                    </SelectItem>
                    <SelectItem key="computers and accessories" value="computers and accessories">
                      Computers and Accessories
                    </SelectItem>
                    <SelectItem key="wears" value="wears">
                      Wears
                    </SelectItem>
                    <SelectItem key="others" value="others">
                      Others
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
      <Button
        className="bg-pi-primary-default"
        disabled={!isValid || isImageUploaded === false}
        onClick={() => send({ type: 'NEXT' })}
        size="large"
      >
        Next
      </Button>
    </div>
  );
}
export default DescribeItemForm;
