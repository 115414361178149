import { useState } from 'react';
import { ChevronLeft, ChevronRight, CheckCircle2, Rocket } from 'lucide-react';

import { StickyNote } from 'lucide-react';

import { useNavigate } from 'react-router-dom';
import { Button } from '@/ui/button';
import AcceptedDeliveries from './components/bookings/accepted-deliveries';
import SuccessfulDeliveries from './components/bookings/successful-deliveries';
import FailedDeliveries from './components/bookings/failed-deliveries';

const CourierBookings = () => {
  const navigate = useNavigate();
  const [deliverySection, setDeliverySection] = useState<
    'accepted' | 'successful' | 'failed' | null
  >(null);
  const goBack = () => {
    if (deliverySection === null) {
      navigate(-1);
    } else {
      setDeliverySection(null);
    }
  };
  const deliveries = [0, 1];
  return (
    <div className="flex h-screen flex-col">
      <button
        className="flex w-full items-center space-x-1 bg-pi-primary-default px-5 py-3 text-base-white"
        onClick={() => goBack()}
        type="button"
      >
        <ChevronLeft />
        {deliverySection === null && <span>Bookings</span>}
        {deliverySection === 'accepted' && <span>Accepted Deliveries</span>}
        {deliverySection === 'successful' && <span>Successful Deliveries</span>}
        {deliverySection === 'failed' && <span>Failed Deliveries</span>}
      </button>
      {deliverySection === 'accepted' && <AcceptedDeliveries />}
      {deliverySection === 'successful' && <SuccessfulDeliveries />}
      {deliverySection === 'failed' && <FailedDeliveries />}
      {deliverySection === null && (
        <>
          {deliveries.length === 0 ? (
            <div
              className=" flex w-full
      flex-1 flex-col items-center justify-center "
            >
              <div
                className="
      flex flex-1 flex-col items-center justify-center p-4
      "
              >
                <div className="flex flex-col items-center">
                  <StickyNote size={50} />
                  <h4 className="mb-4 mt-6 opacity-80">You have no active bookings delivery</h4>
                  <p className="mb-4 text-center text-xs font-semibold opacity-60">
                    See pioneers requesting for delivery in your region
                  </p>
                  <Button
                    className="flex w-full items-center justify-center gap-1 whitespace-nowrap border-pi-primary-default text-base-white"
                    onClick={() => {
                      navigate('/courier');
                    }}
                  >
                    Pickup a delivery
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className=" flex w-full
      flex-1 flex-col gap-4 p-4"
            >
              <button
                className="flex items-center justify-between gap-4 rounded bg-base-white p-4"
                onClick={() => setDeliverySection('accepted')}
                type="button"
              >
                <Rocket size={22} />
                <div className="flex flex-1 flex-col items-start">
                  <h4 className="text-base">Accepted deliveries</h4>
                  <p className="text-xs">Urgent deliveries from your pickups</p>
                </div>
                <span
                  className="flex h-[25px] w-[25px] 
              items-center justify-center rounded-full   bg-[var(--secondary-dark)] text-base-white
              "
                >
                  5
                </span>
                <ChevronRight />
              </button>
              <button
                className="flex items-center justify-between gap-4 rounded bg-base-white p-4"
                onClick={() => setDeliverySection('successful')}
                type="button"
              >
                <CheckCircle2 size={22} />
                <div className="flex flex-1 flex-col items-start">
                  <h4 className="text-base">Successful deliveries</h4>
                  <p className="text-xs">Delivered products, goods or services</p>
                </div>
                <span
                  className="flex h-[25px] w-[25px] 
              items-center justify-center rounded-full   bg-[var(--success-60)] text-base-white
              "
                >
                  51
                </span>
                <ChevronRight />
              </button>

              {/* |||||||||| FAILED DELIVERIES ||||||||||||||| */}
              {/* <button
                className="flex items-center justify-between gap-4 p-4"
                onClick={() => setDeliverySection("failed")}
                type="button"
              >
                <Flag size={18} />
                <div className="flex flex-1 flex-col items-start">
                  <h4 className="text-base">Failed deliveries</h4>
                  <p className="text-xs">
                    All deliveries you sent to other Pailots and Hubs
                  </p>
                </div>
                <span
                  className="flex h-[25px] w-[25px] 
              items-center justify-center rounded-full   bg-rose-800	 text-base-white
              "
                >
                  0
                </span>
                <ChevronRight />
              </button> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CourierBookings;
