import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/ui/form';
import { Input } from '@/ui/input';
import { Textarea } from '@/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/ui/select';
import FormHeader from './form-header';
import { Props } from '../state-machine/state-machine';
import { Button } from '@/ui/button';
import { UseFormReturn } from 'react-hook-form';
import { DeliveryFormData } from '../delivery-form';
import GooglePlacesAutoComplete from './google-places-autocomplete';
import { RiUploadCloud2Line } from 'react-icons/ri';
import InputWithPiIcon from '@/components/Input-with-pi-icon';

function ReviewDeliveryDetailsForm({
  send,
  state,
  form,
  isCourierHired,
}: Props & {
  form: UseFormReturn<DeliveryFormData, any, DeliveryFormData>;
  isCourierHired: boolean;
}) {
  const { watch } = form;
  const selectedFile = watch('itemImage');

  return (
    <div className="flex h-[100dvh] flex-col justify-between gap-4 overflow-auto p-4 pt-0 ">
      <FormHeader
        description={
          state.context.isCourierHired
            ? 'Before you hire the courier for this delivery, confirm the information provided to aid your delivery'
            : 'Before you send out your delivery ad, confirm the information provided to aid your delivery'
        }
        onGoBack={() => send({ type: 'PREVIOUS' })}
        step={null}
        title="Review your delivery"
      />
      <div className="flex flex-1 flex-col gap-4  ">
        <FormField
          name="itemImage"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="relative block w-full cursor-pointer overflow-hidden rounded-lg bg-gray-300 hover:bg-gray-200">
                {selectedFile && selectedFile[0] ? (
                  <div className="h-48 ">
                    <img
                      alt="uploaded file preview"
                      className="absolute inset-0 h-full w-full rounded-lg object-cover"
                      src={URL.createObjectURL(selectedFile[0])}
                    />
                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40">
                      <div className="flex w-64 cursor-pointer flex-col items-center justify-center gap-2 rounded-full bg-[#1E1E1E] py-2 text-gray-50 hover:bg-[#1E1E1E]">
                        <RiUploadCloud2Line className="mr-2 h-5 w-5 text-gray-300" />
                        <span className="text-white text-sm">Edit image</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-center bg-gray-200 py-10">
                    <div className="flex w-64 cursor-pointer flex-col items-center justify-center gap-2 rounded-full bg-[#1E1E1E] py-2 text-gray-50 hover:bg-[#1E1E1E]">
                      <RiUploadCloud2Line className="mr-2 h-5 w-5 text-gray-300" />
                      <span>Upload item picture</span>
                    </div>
                  </div>
                )}
                <FormControl>
                  <Input
                    accept="image/*"
                    className="absolute inset-0 h-full w-full cursor-pointer opacity-0"
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                    ref={field.ref}
                    type="file"
                  />
                </FormControl>
              </FormLabel>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="receiverName"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Receiver&apos;s Username</FormLabel>
              <FormControl>
                <Input type="text" {...field} placeholder="Example: nicolas" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="itemName"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Name of item(s)</FormLabel>
              <FormControl>
                <Input type="text" {...field} placeholder="e.g: Taucci bag" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="itemDescription"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Item(s) description</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  placeholder="e.g: Silk brown bag with suspended leather rope. Color brown and rough surface leather. "
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {!isCourierHired ? (
          <FormField
            name="price"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Price for delivery</FormLabel>
                <FormControl>
                  <InputWithPiIcon>
                    <Input
                      step="any"
                      className="mt-0 rounded-none border-0 border-none bg-transparent focus:border-0 focus:border-none"
                      min={0}
                      type="number"
                      {...field}
                      placeholder="e.g 0.0002"
                    />
                  </InputWithPiIcon>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : null}
        <FormField
          name="itemCategory"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Category</FormLabel>
              <Select name={field.name} onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent position="item-aligned">
                  <SelectItem key="beauty and healthcare" value="beauty and healthcare">
                    Beauty and Healthcare
                  </SelectItem>
                  <SelectItem key="clothes and accesories" value="clothes and accesories">
                    Clothes and Accesories
                  </SelectItem>
                  <SelectItem key="consumer electronics" value="consumer electronics">
                    Consumer Electronics
                  </SelectItem>
                  <SelectItem key="electronics" value="electronics">
                    Electronics
                  </SelectItem>
                  <SelectItem key="foods and beverages" value="foods and beverages">
                    Foods and Beverages
                  </SelectItem>
                  <SelectItem key="household items" value="household items">
                    Household Items
                  </SelectItem>
                  <SelectItem key="computers and accessories" value="computers and accessories">
                    Computers and Accessories
                  </SelectItem>
                  <SelectItem key="wears" value="wears">
                    Wears
                  </SelectItem>
                  <SelectItem key="others" value="others">
                    Others
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          name="pickupAddress"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Pickup location</FormLabel>
              <FormControl>
                {/* <Input type="text" {...field} placeholder="e.g Lekki phase 1" /> */}
                <GooglePlacesAutoComplete
                  onAddressSelected={(addressComponents) => console.log(addressComponents)}
                  placeholder="e.g Ajah"
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="dropOffAddress"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Drop-off location</FormLabel>
              <FormControl>
                {/* <Input type="text" {...field} placeholder="e.g Lekki phase 1" /> */}
                <GooglePlacesAutoComplete
                  onAddressSelected={(addressComponents) => console.log(addressComponents)}
                  placeholder="e.g Lekki phase 1"
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button size="large" type="submit">
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ReviewDeliveryDetailsForm;
