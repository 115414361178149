import BackButtonIcon from "./back-button-icon";

function FormHeader({
  title,
  description,
  step,
  onGoBack,
}: {
  title: string;
  description: string;
  step: number | null;
  onGoBack: () => void;
}) {
  return (
    <div className="my-5 grid grid-cols-4 gap-1">
      <div className="col-span-3 flex gap-3">
        <BackButtonIcon onClick={onGoBack} />
        <div>
          <h4 className=" text-pi-primary-default">{title}</h4>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
      <div className="flex justify-end">
        <p className="flex text-pi-primary-default">
          {step ? `0${step} of 04` : ""}
        </p>
      </div>
    </div>
  );
}

export default FormHeader;
